import cn from "classnames"
import { graphql } from "gatsby"
import { z } from "zod"
import Image, { imagePropsSchema } from "../../../resolvers/image"
import Adopters from "../../freestanding/adopters"
import Button, { buttonPropsSchema } from "../../freestanding/button/button"
import Container from "../../freestanding/container"
import Content from "../../freestanding/content"
import { FancyTextAlternatives } from "../../freestanding/fancy-text-alternatives"
import GlobeWrapper from "../../freestanding/globe-wrapper"
import Highlight from "../../freestanding/highlight"
import NewsBanner, { newsPropsSchema } from "../../freestanding/news-banner"
import Overline, { overlineSchema } from "../../freestanding/overline/overline"
import Section from "../../freestanding/section"
import Wrapper from "../../freestanding/wrapper"

const nullishStringSchema = z
  .string()
  .nullish()
  .transform((x) => x ?? undefined)

export const heroPropsSchema = z
  .object({
    overline: overlineSchema.nullish(),
    title: z
      .string()
      .or(z.custom<JSX.Element>())
      .nullish()
      .transform((x) => x ?? undefined),
    description: nullishStringSchema,
    buttons: buttonPropsSchema.array().nullish(),
    caption: nullishStringSchema,
    image: imagePropsSchema.nullish(),
    news: newsPropsSchema.nullish(),
    showAdopters: z.boolean().nullish(),
    isLandingPage: z.boolean().nullish().optional(),
    h2: z.boolean().nullish(),
  })
  .passthrough()

const Hero = ({
  overline,
  title,
  description,
  image,
  caption,
  buttons,
  news,
  isLandingPage = false,
  showAdopters = false,
  h2 = false,
}: z.infer<typeof heroPropsSchema>) => {
  const Headline = h2 ? "h2" : "h1"

  return (
    <Section>
      <Container>
        <Wrapper>
          <Content
            colWidthLg={description ? 6 : 12}
            colWidthXL={description ? 6 : 12}
            boxed={!!description}
            className={"relative z-10"}
          >
            <div className="flex flex-col space-y-8">
              {news && <NewsBanner {...news} />}
              {overline && (
                <Overline prefix={overline.prefix} title={overline.title} />
              )}
              <div className="flex flex-col space-y-4 md:space-y-6">
                <Headline
                  className={cn(
                    "dark:text-cyan-50 text-balance font-semibold text-indigo-900",
                    !h2 && !description && "xl:text-8xl",
                    h2 ? "text-4xl md:text-5xl" : "text-5xl md:text-6xl",
                  )}
                >
                  <Highlight>
                    {isLandingPage && typeof title === "string" ? (
                      <FancyTextAlternatives title={title} />
                    ) : (
                      title
                    )}
                  </Highlight>
                </Headline>
                <p className="dark:text-gray-300 dark:bg-gray-800 bg-gray-50 text-lg text-gray-600">
                  <Highlight>{description}</Highlight>
                </p>
              </div>
            </div>
            {buttons && buttons.length > 0 && (
              <div className="grid gap-y-4">
                <div className="flex flex-col gap-4 md:w-fit md:flex-row">
                  {buttons.map((props, idx) => (
                    <Button
                      key={idx}
                      className="w-full whitespace-nowrap md:w-min"
                      {...props}
                      iconRight={
                        props.variant == "primary" || buttons.length == 1
                          ? "arrow-right"
                          : undefined
                      }
                    />
                  ))}
                </div>
                <p className="dark:text-gray-500 text-sm text-gray-400">
                  <Highlight>{caption}</Highlight>
                </p>
              </div>
            )}
          </Content>
          {image && (
            <div className="col-span-full flex flex-col lg:col-span-6">
              <Image {...image} />
            </div>
          )}
          {isLandingPage && (
            <div className="col-span-full flex flex-col lg:col-span-6">
              <GlobeWrapper />
            </div>
          )}
        </Wrapper>
        {showAdopters && (
          <div className="col-span-full">
            <Adopters />
          </div>
        )}
      </Container>
    </Section>
  )
}

export default Hero

export const query = graphql`
  fragment Heros on PagesJson {
    blocks {
      id
      type
      ...Overline
      title
      description
      h2
      buttons {
        button {
          title
          to
          variant
          analytics {
            action
            track {
              GenericPlacement
              GenericVariant
            }
          }
        }
      }
      caption
      photo {
        alt
        padding
        image {
          id
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
        backgroundColor
        objectFit
      }
      showAdopters
      newsOptional {
        news {
          prefix
          badgeTitle
          title
          url
        }
      }
    }
  }
`
