import loadable from "@loadable/component"
import cn from "classnames"
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { z } from "zod"
import Image, { imagePropsSchema } from "../../../../resolvers/image"
import Content from "../../../freestanding/content"
import { FancyTextAlternatives } from "../../../freestanding/fancy-text-alternatives"
import Highlight from "../../../freestanding/highlight"
import NewsBanner, { newsPropsSchema } from "../../../freestanding/news-banner"
import Overline, {
  overlineSchema,
} from "../../../freestanding/overline/overline"
import Section from "../../../freestanding/section"
import Wrapper from "../../../freestanding/wrapper"
import Adopters from "../adopters/adopters"
import Button, { buttonPropsSchema } from "../button/button"
import Container from "../container"
import { heroFactSchema } from "./hero-facts"

const HeroFacts = loadable(() => import("./hero-facts"))

const nullishStringSchema = z
  .string()
  .nullish()
  .transform((x) => x ?? undefined)

export const heroPropsSchema = z
  .object({
    overline: overlineSchema.nullish(),
    title: z
      .string()
      .or(z.custom<JSX.Element>())
      .nullish()
      .transform((x) => x ?? undefined),
    description: nullishStringSchema,
    buttons: buttonPropsSchema.array().nullish(),
    caption: nullishStringSchema,
    image: imagePropsSchema.nullish(),
    news: newsPropsSchema.nullish(),
    showAdopters: z.boolean().nullish(),
    isFancy: z.boolean().nullish(),
    h2: z.boolean().nullish(),
    facts: heroFactSchema
      .array()
      .nullish()
      .transform((x) => x ?? undefined),
  })
  .passthrough()

const Hero = ({
  overline,
  title,
  description,
  image,
  caption,
  buttons,
  news,
  facts,
  isFancy = false,
  showAdopters = false,
  h2 = false,
}: z.infer<typeof heroPropsSchema>) => {
  const Headline = h2 ? "h2" : "h1"

  return (
    <Section className="relative">
      <Container className="z-10">
        <Wrapper>
          <Content
            colWidthLg={description ? 6 : 12}
            colWidthXL={description ? 6 : 12}
            boxed={!!description}
            className={"relative z-10"}
          >
            <div className="flex flex-col space-y-8">
              {news && <NewsBanner {...news} />}
              {overline && (
                <Overline prefix={overline.prefix} title={overline.title} />
              )}
              <div className="flex flex-col space-y-4 md:space-y-6">
                <Headline
                  className={cn(
                    "text-balance font-medium text-gray-900",
                    !h2 &&
                      !description &&
                      "text-left xl:max-w-[18ch] xl:text-6xl",
                    h2 ? "text-4xl md:text-6xl" : "text-4xl md:text-6xl",
                  )}
                >
                  <Highlight>
                    {isFancy && typeof title === "string" ? (
                      <FancyTextAlternatives title={title} />
                    ) : (
                      title
                    )}
                  </Highlight>
                </Headline>
                <p className="dark:text-gray-300 dark:bg-gray-800 text-lg text-gray-600">
                  <Highlight>{description}</Highlight>
                </p>
              </div>
            </div>
            {buttons && buttons.length > 0 && (
              <div className="grid gap-y-4">
                <div className="flex flex-col gap-4 md:w-fit md:flex-row">
                  {buttons.map((props, idx) => (
                    <Button
                      key={idx}
                      className="w-full whitespace-nowrap md:w-min"
                      {...props}
                    />
                  ))}
                </div>
                <p className="dark:text-gray-500 text-sm text-gray-400">
                  <Highlight>{caption}</Highlight>
                </p>
              </div>
            )}
          </Content>
          {image && description && (
            <div className="col-span-full flex flex-col lg:col-span-6">
              <Image {...image} />
            </div>
          )}
          {image && !description && (
            <div className="col-span-full flex aspect-screenshot flex-col lg:col-span-12">
              <Image {...image} />
            </div>
          )}
          {facts && (
            <div className="col-span-full flex flex-col lg:grid">
              <HeroFacts {...facts} />
            </div>
          )}
        </Wrapper>
        {showAdopters && (
          <div className="col-span-full">
            <Adopters />
          </div>
        )}
      </Container>
      {!image && (
        <StaticImage
          src={"./bg-globe.png"}
          alt="globe"
          className="absolute inset-0 z-0 w-full"
          placeholder="none"
          layout="fullWidth"
        />
      )}
    </Section>
  )
}

export default Hero

export const query = graphql`
  fragment HeroNext on PagesJson {
    blocks {
      id
      type
      ...Overline
      title
      description
      h2
      buttons {
        button {
          title
          to
          variant
          analytics {
            action
            track {
              GenericPlacement
              GenericVariant
            }
          }
        }
      }
      facts {
        fact {
          title
          description
          icon
        }
      }
      caption
      photo {
        alt
        padding
        image {
          id
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
        backgroundColor
        objectFit
      }
      showAdopters
      isFancy
      newsOptional {
        news {
          prefix
          badgeTitle
          title
          url
        }
      }
    }
  }
`
