import { graphql } from "gatsby"
import { z } from "zod"
import Image, { imagePropsSchema } from "../../../../resolvers/image"
import Highlight from "../../../freestanding/highlight"
import Section from "../../../freestanding/section"
import Button, { buttonPropsSchema } from "../../next/button/button"
import Overline, { overlinePropsSchema } from "../../next/overline/overline"

export const ctaFillPropsSchema = z.object({
  title: z.string(),
  description: z.string(),
  overline: overlinePropsSchema.nullish(),
  buttons: buttonPropsSchema.array().nullish(),
  image: imagePropsSchema.nullish(),
  className: z
    .string()
    .nullish()
    .transform((x) => x ?? undefined),
})

const CTAFill = ({
  title = "",
  description,
  buttons,
  className,
  image,
  overline,
}: z.infer<typeof ctaFillPropsSchema>) => {
  return (
    <Section className={className}>
      <div className="relative flex flex-col gap-8 overflow-hidden rounded-2xl px-12 py-12 md:px-24 lg:min-h-[680px] lg:flex-row">
        <div className="flex flex-grow self-center">
          <div className="grid gap-y-4 md:gap-y-6 lg:max-w-[535px]">
            {overline && <Overline {...overline} />}
            {title && (
              <h2 className="max-w-sm text-balance text-4xl font-medium text-gray-900 md:text-5xl">
                <Highlight>{title}</Highlight>
              </h2>
            )}
            {description && (
              <p className="dark:text-gray-300 text-lg text-gray-600">
                <Highlight>{description}</Highlight>
              </p>
            )}
            {buttons && buttons.length > 0 && (
              <div className="flex flex-col gap-4 md:w-fit md:flex-row">
                {buttons.map((button, idx) => (
                  <Button
                    key={idx}
                    {...button}
                    className="w-full whitespace-nowrap md:w-min"
                  />
                ))}
              </div>
            )}
          </div>
        </div>
        {image && (
          <div className="absolute left-0  top-0 -z-10 col-span-full flex h-full w-full flex-col  lg:col-span-12">
            <Image {...image} className="h-full object-left" />
          </div>
        )}
      </div>
    </Section>
  )
}

export default CTAFill

export const query = graphql`
  fragment CtaNextFill on PagesJson {
    blocks {
      id
      type
      title
      description
      className
      buttons {
        button {
          title
          to
          variant
          analytics {
            action
            track {
              GenericPlacement
              GenericVariant
              GenericValue
              GenericCurrency
            }
          }
        }
      }
      photo {
        alt
        padding
        image {
          id
          childImageSharp {
            fluid(quality: 100, maxWidth: 1000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        backgroundColor
        objectFit
      }
    }
  }
`
