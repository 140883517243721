import { graphql } from "gatsby"
import { z } from "zod"
import Image, { imagePropsSchema } from "../../../resolvers/image"
import Button, { buttonPropsSchema } from "../../freestanding/button/button"
import Container from "../../freestanding/container"
import Highlight from "../../freestanding/highlight"
import Overline, {
  overlinePropsSchema,
} from "../../freestanding/overline/overline"
import Section from "../../freestanding/section"

export const ctaPropsSchema = z.object({
  title: z.string(),
  description: z.string(),
  buttons: buttonPropsSchema.array().nullish(),
  overline: overlinePropsSchema.nullish(),
  image: imagePropsSchema.nullish(),
})

const CTA = ({
  title,
  description,
  buttons,
  overline,
  image,
}: z.infer<typeof ctaPropsSchema>) => {
  return (
    <Section>
      <Container>
        <div className="col-span-full flex flex-col md:grid md:grid-cols-2 md:gap-x-8">
          <div className="md:flex md:flex-col md:justify-center xl:max-w-[535px]">
            {overline && <Overline {...overline} />}
            <h2 className="mt-9 text-5xl font-semibold text-indigo-900">
              <Highlight>{title}</Highlight>
            </h2>
            <p className="mt-4 text-lg text-gray-600">
              <Highlight>{description}</Highlight>
            </p>
            <div className="mt-8 flex flex-col gap-4 md:w-fit md:flex-row">
              {buttons?.map((button, index) => (
                <Button
                  key={index}
                  {...button}
                  iconRight="arrow-right"
                  className="w-full whitespace-nowrap md:w-min"
                />
              ))}
            </div>
          </div>
          {image && <Image {...image} className="mt-8 md:mt-0" />}
        </div>
      </Container>
    </Section>
  )
}

export default CTA

export const query = graphql`
  fragment CTAs on PagesJson {
    blocks {
      id
      type
      title
      description
      buttons {
        button {
          title
          to
          variant
          analytics {
            action
            track {
              GenericPlacement
              GenericVariant
              GenericValue
              GenericCurrency
            }
          }
        }
      }
      photo {
        alt
        padding
        image {
          id
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
        backgroundColor
        objectFit
      }
      overlineOptional {
        overline {
          prefix
          title
        }
      }
    }
  }
`
