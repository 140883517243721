import { Tab, Disclosure } from "@headlessui/react"
import cn from "classnames"
import { graphql } from "gatsby"
import { CheckCircle } from "phosphor-react"
import { Fragment } from "react"
import z from "zod"
import Container from "../../../freestanding/container"
import Highlight from "../../../freestanding/highlight"
import Section from "../../../freestanding/section"
import Button, { buttonPropsSchema } from "../button/button"

const comparisonTableColumnsSchema = z
  .array(
    z.object({
      name: z.string().default(""),
      id: z.string().default(""),
    }),
  )
  .default([])

const comparisonTableSectionSchema = z
  .array(
    z.object({
      name: z.string().default(""),
      features: z.array(
        z.object({
          name: z.string().default(""),
          values: z.array(
            z.object({
              id: z.string().default(""),
              value: z.string().default(""),
            }),
          ),
        }),
      ),
    }),
  )
  .default([])

export const comparisonTablePropsSchema = z.object({
  headline: z.string().nullish().default(""),
  description: z.string().nullish().default(""),
  buttons: buttonPropsSchema.array().nullish(),
  columns: comparisonTableColumnsSchema,
  sections: comparisonTableSectionSchema,
})

type ComparisonTableSectionProps = z.infer<typeof comparisonTableSectionSchema>
type ComparisonTableColumnsProps = z.infer<typeof comparisonTableColumnsSchema>

type ComparisonTableProps = z.infer<typeof comparisonTablePropsSchema>

interface Tier {
  name: string
  headline: string
  price: number | string
  [key: string]: any
}

function ComparisonTable({
  headline,
  description,
  columns,
  sections,
  buttons = [],
}: ComparisonTableProps) {
  return (
    <Section>
      <Container>
        <div className="col-span-full">
          <div className="grid w-auto gap-y-4 md:gap-y-6 lg:flex lg:w-full lg:flex-row lg:justify-between lg:gap-x-8">
            <h2 className="text-4xl font-medium text-gray-900 md:text-5xl lg:max-w-lg lg:flex-1">
              <Highlight>{headline}</Highlight>
            </h2>
            <div className="flex flex-col gap-y-8 lg:max-w-md">
              <p className="dark:text-gray-300 text-lg text-gray-600">
                <Highlight>{description}</Highlight>
              </p>
            </div>
          </div>
          <ComparisonTableDesktop columns={columns} sections={sections} />
          <ComparisonTableMobile columns={columns} sections={sections} />
        </div>
        {buttons && buttons.length > 0 && (
          <div className="flex flex-col gap-4 md:w-fit md:flex-row">
            {buttons.map((button, idx) => (
              <Button
                key={idx}
                {...button}
                className="w-full whitespace-nowrap md:w-min"
              />
            ))}
          </div>
        )}
      </Container>
    </Section>
  )
}

interface ComparisonTableContentProps {
  columns: ComparisonTableColumnsProps
  sections: ComparisonTableSectionProps
}

const BooleanCell = ({ value }: { value: boolean }) => {
  return value ? (
    <CheckCircle
      color="rgb(16, 185, 129)"
      aria-label=""
      weight="fill"
      size={14}
    />
  ) : (
    <p className="leading-tight text-gray-400">×</p>
  )
}

const ArrayCell = ({ value }: { value: any[] }) => {
  return (
    <div className="relative flex flex-col items-center gap-2 xl:items-start">
      <BooleanCell value={value[0] === "true"} />
      <div className="max-w-full leading-tight text-gray-600">{value[1]}</div>
    </div>
  )
}

const StringCell = ({ value }: { value: string }) => {
  return <p className="leading-tight text-gray-600">{value}</p>
}

interface TableCellProps {
  value: string
}

const TableCell = ({ value }: TableCellProps) => {
  let content = null

  if (value === "true" || value === "false") {
    content = <BooleanCell value={value === "true"} />
  } else if (typeof value === "string") {
    try {
      const parsedValue = JSON.parse(value)
      if (Array.isArray(parsedValue)) {
        content = <ArrayCell value={parsedValue} />
      } else {
        content = <StringCell value={value} />
      }
    } catch {
      content = <StringCell value={value} />
    }
  }

  return (
    <div className="flex h-full flex-col justify-center border-r border-gray-100 p-2 text-gray-400 last:border-r-0">
      {content}
    </div>
  )
}

const ComparisonTableDesktop = ({
  columns = [],
  sections,
}: ComparisonTableContentProps) => {
  return (
    <div className="hidden gap-y-4 xl:flex xl:flex-col">
      <div
        className="mt-8 grid grid-cols-6 py-4 text-sm"
        style={{
          gridTemplateColumns: `repeat(${columns?.length + 1}, minmax(0, 1fr)`,
        }}
      >
        <div className="py-1" />
        {columns?.map((tier, tIndex) => (
          <div key={tIndex} className="flex flex-col items-start gap-y-4 px-2">
            <h2 className="text-balance text-xl font-medium text-gray-900">
              {tier.name}
            </h2>
          </div>
        ))}
      </div>
      {sections.map((section) => (
        <Disclosure
          key={`comparison-section-${section.name}`}
          defaultOpen={true}
        >
          {({ open }) => (
            <>
              <Disclosure.Button className="w-full border-b border-indigo-200 last:border-none">
                <div className="flex w-full items-baseline justify-between py-2">
                  <h3 className="text-base font-medium text-gray-900">
                    {section.name}
                  </h3>
                  <p className="text-indigo-600">{open ? "-" : "+"}</p>
                </div>
              </Disclosure.Button>
              <Disclosure.Panel>
                {section.features.map(({ values, name }, fIndex) => (
                  <div
                    key={fIndex}
                    className="grid min-h-[4rem] items-center border-t border-gray-100 text-sm first:border-none"
                    style={{
                      gridTemplateColumns: `repeat(${
                        columns?.length + 1
                      }, minmax(0, 1fr)`,
                    }}
                  >
                    <div className="flex h-full flex-col justify-center border-r border-gray-100 py-2 pr-2">
                      <p className="leading-tight text-gray-900">{name}</p>
                    </div>
                    {columns.map(({ id }, tIndex) => {
                      const value = values.find((v) => v.id === id)?.value ?? ""

                      return (
                        <div
                          key={`${tIndex}-${fIndex}-${id}`}
                          className="flex h-full flex-col justify-center border-r border-gray-100 p-2 text-gray-400 last:border-r-0"
                        >
                          <TableCell
                            key={`${tIndex}-${fIndex}`}
                            value={value}
                          />
                        </div>
                      )
                    })}
                  </div>
                ))}
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      ))}
    </div>
  )
}

const ComparisonTableMobile = ({
  columns = [],
  sections,
}: ComparisonTableContentProps) => {
  return (
    <Tab.Group as={"div"} className="grid w-auto xl:hidden">
      <Tab.List className="mb-4 flex space-x-6 overflow-x-auto border-b border-indigo-200">
        {columns.map((tier, index) => (
          <Tab as={Fragment} key={index}>
            {({ selected }) => (
              <button
                key={index}
                className={cn(
                  selected
                    ? "border-indigo-600 text-indigo-600"
                    : "border-transparent text-gray-500 hover:border-indigo-900 hover:text-indigo-900",
                  "rounded-none border-b-2 border-solid py-6 text-sm font-normal max-md:whitespace-nowrap",
                )}
                aria-controls={tier.name}
              >
                {tier.name}
              </button>
            )}
          </Tab>
        ))}
      </Tab.List>

      <Tab.Panels className="w-full">
        {columns.map((tier, index) => (
          <Tab.Panel
            key={index}
            className="flex flex-col justify-start gap-y-0"
          >
            {sections.map((section) => (
              <Disclosure
                key={`comparison-table-mobile-section-${section.name}`}
                defaultOpen={true}
              >
                {({ open }) => (
                  <>
                    <Disclosure.Button className="border-b border-indigo-200 last:border-none">
                      <div className="flex w-full items-baseline justify-between py-2 ">
                        <h3 className="text-sm font-medium text-gray-900">
                          {section.name}
                        </h3>
                        <p className="text-indigo-600">{open ? "-" : "+"}</p>
                      </div>
                    </Disclosure.Button>
                    <Disclosure.Panel>
                      <div className="flex flex-col pb-8">
                        {section.features.map((feature, fIndex) => {
                          const value =
                            feature.values.find((v) => v.id === tier.id)
                              ?.value ?? ""

                          return (
                            <div
                              key={fIndex}
                              className="grid grid-cols-2 flex-row border-t border-gray-100 text-sm first:border-none"
                            >
                              <div className="h-full border-r border-gray-200 py-2 pr-2">
                                <p className="leading-none text-gray-900">
                                  {feature.name}
                                </p>
                              </div>
                              <div className="flex h-full justify-center py-2 text-center text-gray-400">
                                <TableCell key={fIndex} value={value} />
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </Tab.Panel>
        ))}
      </Tab.Panels>
    </Tab.Group>
  )
}

export default ComparisonTable

export const query = graphql`
  fragment ComparisonTable on PagesJson {
    blocks {
      id
      type
      headline
      columns {
        id
        name
      }
      buttons {
        button {
          title
          to
          variant
          analytics {
            action
            track {
              GenericPlacement
              GenericVariant
            }
          }
        }
      }
      sections {
        name
        features {
          name
          values {
            id
            value
          }
        }
      }
    }
  }
`
