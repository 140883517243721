import { graphql, PageProps } from "gatsby"
import Layout from "../relaunch/components/layouts/layout/layout"
import PageBuilder from "../relaunch/components/page-builder"

const Page = ({ data }: PageProps<Queries.PageQuery>) => {
  return (
    <Layout
      minimalNavigation={!!data.pagesJson?.minimalNavigation}
      bgNext={data.pagesJson?.bgNext ?? false}
    >
      <PageBuilder
        live={{
          data: data,
        }}
      />
    </Layout>
  )
}

export const Head = ({ data }: PageProps<Queries.PageQuery>) => {
  const pageTitle = data?.pagesJson?.title || ""
  const seo = data.pagesJson?.seo

  const metaTitle = seo?.title ? seo?.title : pageTitle

  return (
    <>
      <title>{metaTitle}</title>
      {seo?.description && (
        <meta name="description" content={seo.description} />
      )}
      <meta property="og:title" content={metaTitle} />
      {seo?.description && (
        <meta property="og:description" content={seo.description} />
      )}
      <meta property="og:type" content="website" />
      <meta property="twitter:card" content="summary" />
      <meta property="twitter:author" content="ORY" />
      <meta property="twitter:title" content={metaTitle} />
      {seo?.description && (
        <meta property="twitter:description" content={seo.description!} />
      )}
    </>
  )
}

export default Page

export const basicPageQuery = graphql`
  query Page($path: String!) {
    pagesJson(permalink: { eq: $path }) {
      title
      bgNext
      minimalNavigation
      ...HeroNext
      ...FeatureNext
      ...FeatureNextOverview
      ...CtaNext
      ...CtaNextFill
      ...AdoptersHeadline
      ...VerticalCards
      ...FaqSection
      ...ComparisonTable
      ...Heros
      ...CTAs
      ...CTAFill
      ...Offices
      ...FeatureOverview
      ...PeopleList
      ...Feature
      ...General
      ...Quotes
      seo {
        title
        description
      }
    }
    people: allMdx(filter: { frontmatter: { type: { eq: "person" } } }) {
      nodes {
        frontmatter {
          name
          role
          photo {
            alt
            image {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
          socials {
            link
            type
          }
        }
      }
    }
    testimonials: allMdx(
      filter: { frontmatter: { type: { eq: "testimonial" } } }
    ) {
      nodes {
        frontmatter {
          person
          jobTitle
          description
          photo {
            alt
            image {
              publicURL
            }
          }
        }
      }
    }
  }
`
